import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import "./userlist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newEventName, setNewEventName] = useState("");
  const [newEventImage, setNewEventImage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      const formData = new FormData();
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:5004/greenmitra/list/events",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.result) {
          const eventsData = response.data.list.map((event) => ({
            id: event.ev_id,
            title: event.ev_name,
            image: `https://lunarsenterprises.com:5004/${event.ev_image}`,
          }));
          setEvents(eventsData);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleDelete = async () => {
    try {
      const data = { ev_id: eventToDelete };
      const response = await axios.post(
        "https://lunarsenterprises.com:5004/greenmitra/delete",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.result) {
        toast.success("Event deleted successfully");
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.id !== eventToDelete)
        );
      } else {
        console.error("Failed to delete event:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
    setShowDeleteModal(false);
    setEventToDelete(null);
  };

  const handleAddEvent = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("ev_name", newEventName);
    formData.append("image", newEventImage);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5004/greenmitra/add/events",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.result) {
        toast.success("Event added successfully");
        window.location.reload();
        const newEvent = {
          id: response.data.ev_id,
          title: newEventName,
          image: `https://lunarsenterprises.com:5004/${response.data.ev_image}`,
        };
        setEvents((prevEvents) => [...prevEvents, newEvent]);
        setShowAddModal(false);
        setNewEventName("");
        setNewEventImage(null);
      } else {
        console.error("Failed to add event:", response.data.message);
      }
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  return (
    <Container>
      <h2 className="my-4 text-center">Events</h2>
      <div className="d-flex justify-content-end mb-5">
        <Button className="add-button me-3" onClick={() => setShowAddModal(true)}>
          Add <FontAwesomeIcon icon={faPlus} className="ms-2" />
        </Button>
      </div>

      <Row className="event-rows">
        {events.map((event) => (
          <Col key={event.id} xs={12} sm={6} md={3} className="mb-4">
            <Card className="position-relative">
              <div className="delete-button-container">
                <FontAwesomeIcon
                  icon={faTrash}
                  className="delete-icon"
                  onClick={() => {
                    setEventToDelete(event.id);
                    setShowDeleteModal(true);
                  }}
                />
              </div>
              <Card.Img
                className="events-image"
                variant="top"
                src={event.image}
              />
              <Card.Body>
                <Card.Title className="event-title">{event.title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Add Event Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddEvent}>
            <Form.Group className="mb-3" controlId="eventName">
              <Form.Label>Event Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event name"
                value={newEventName}
                onChange={(e) => setNewEventName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="eventImage">
              <Form.Label>Event Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => setNewEventImage(e.target.files[0])}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Event
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this event?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </Container>
  );
};

export default Events;
