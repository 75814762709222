import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./Products.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServicesTable = () => {
  const [services, setServices] = useState([]);
  const [serviceName, setServiceName] = useState('');
  const [description, setDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // For confirmation modal
  const [serviceToDelete, setServiceToDelete] = useState(null); // Service ID to delete

  useEffect(() => {
    axios.post("https://lunarsenterprises.com:5004/greenmitra/list/service")
      .then((response) => {
        if (response.data.result && Array.isArray(response.data.list)) {
          setServices(response.data.list);
        } else {
          console.error("Invalid data structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the services:", error);
      });
  }, []);

  const handleAddService = () => {
    const newService = { service_name: serviceName, description: description };

    axios.post("https://lunarsenterprises.com:5004/greenmitra/add/service", newService)
      .then((response) => {
        if (response.data.result) {
          setServices((prevServices) => [...prevServices, response.data.newService]);
          setServiceName("");
          setDescription("");
          setShowModal(false);
        }
      })
      .catch((error) => {
        console.error("There was an error adding the service:", error);
      });
  };

  const handleEditService = () => {
    const updatedService = {
      service_id: editingServiceId,
      service_name: serviceName,
      description: description,
    };

    axios.post("https://lunarsenterprises.com:5004/greenmitra/edit/service", updatedService)
      .then((response) => {
        if (response.data.result) {
          setServices((prevServices) =>
            prevServices.map((service) =>
              service.s_id === editingServiceId ? { ...service, s_name: serviceName, s_description: description } : service
            )
          );
          setServiceName("");
          setDescription("");
          setEditingServiceId(null);
          setShowModal(false);
        }
      })
      .catch((error) => {
        console.error("There was an error editing the service:", error);
      });
  };

  const handleClickEdit = (service) => {
    setEditingServiceId(service.s_id);
    setServiceName(service.s_name);
    setDescription(service.s_description);
    setShowModal(true);
  };

  const handleDeleteService = () => {
    axios.post("https://lunarsenterprises.com:5004/greenmitra/delete", { s_id: serviceToDelete })
      .then((response) => {
        if (response.data.result) {
          setServices((prevServices) =>
            prevServices.filter((service) => service.s_id !== serviceToDelete)
          );
          setServiceToDelete(null);
          setShowConfirmModal(false);
        }
      })
      .catch((error) => {
        console.error("There was an error deleting the service:", error);
      });
  };

  const handleDeleteClick = (serviceId) => {
    setServiceToDelete(serviceId);
    setShowConfirmModal(true); // Show confirmation modal
  };

  return (
    <>
      <div className="Services-heading">
        <h4>SERVICES</h4>
      </div>

      <Row className="mb-3">
        <Col className="d-flex justify-content-end">
          <Button variant="primary" className="me-2 add-button" onClick={() => setShowModal(true)}>
            Add <FontAwesomeIcon icon={faPlus} className="ms-2" />
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th className="services-names">Services</th>
            <th className="services-description">Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {services.length > 0 ? (
            services.map((service, index) => (
              <tr key={service.s_id}>
                <td>{index + 1}</td>
                <td>{service.s_name}</td>
                <td>{service.s_description}</td>
                <td>
                  <Button
                    className="services-edit-delete"
                    variant="dark"
                    style={{ marginRight: "2px" }}
                    onClick={() => handleClickEdit(service)}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </Button>
                  <Button
                    className="services-edit-delete"
                    variant="dark"
                    onClick={() => handleDeleteClick(service.s_id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No services available
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editingServiceId ? "Edit Service" : "Add New Service"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="serviceName">
              <Form.Label>Service Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter service name"
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="description" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={editingServiceId ? handleEditService : handleAddService}>
            {editingServiceId ? "Save Changes" : "Add Service"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this service?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteService}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default ServicesTable;
