import React, { useEffect, useState } from "react";
import { Spinner, Alert, Table, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./Dashboard.css";
import moment from "moment";




function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchOrders = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5004/greenmitra/list/contact"
      );
      if (response.data.result) {
        const data = response.data.list;
        setOrders(data);
        setFilteredOrders(data);
      } else {
        throw new Error(response.data.message || "Failed to fetch orders");
      }
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
    
  }, []);

  const handleFilter = () => {
    if (startDate && endDate) {
      // Parse the start and end date using moment, ensuring it's set to the start and end of the day
      const start = moment(startDate).startOf('day'); // Start of the selected start date
      const end = moment(endDate).endOf('day'); // End of the selected end date
      
      const filtered = orders.filter((order) => {
        // Parse the order date using moment and compare
        const orderDate = moment(order.c_date); // Assuming `order.c_id` is a date string
        return orderDate.isBetween(start, end, null, '[]'); // '[]' includes both start and end
      });
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orders); // Reset to all orders if no dates are selected
    }
  };
  

  return (
    <div>
      <div className="container">
        <div className="dashboard-first">
          <div className="column">
            <h4 className="dashboard-details">NUMBER OF USERS</h4>
            <p className="data-value">{orders.length}</p>
          </div>
        </div>

        <div className="dashboard-header">
          <h3>ENQUIRIES</h3>
        </div>

        <div className="d-flex justify-content-end align-items-center p-3 filter-container">
          {showDatePicker && (
            <>
              <Form.Control
                type="date"
                className="me-2 date-picker"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Form.Control
                type="date"
                className="me-2 date-picker"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <Button
                className="filter-button me-2"
                variant="success"
                onClick={handleFilter}
              >
                Apply
              </Button>
            </>
          )}
          <Button
            className="filter-button"
            variant="primary"
            onClick={() => setShowDatePicker(!showDatePicker)}
            style={{ marginRight: "10px" }} // Adjust the space here
          >
            {showDatePicker ? "Close" : "Filter"}
          </Button>
          {/* <Button className="filter-button" variant="primary">
            <FontAwesomeIcon icon={faDownload} />
          </Button> */}
        </div>

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Loading orders...</p>
          </div>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : filteredOrders.length > 0 ? (
          <div className="table-responsive-container">
            <Table className="custom-table responsive" responsive>
              <thead>
                <tr>
                <th>Sl No:</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Service</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order,index) => (
                  <tr key={order.c_id}>
                    <td>{index+1}</td>
                    <td>{moment(order.c_date).format('DD-MM-YYYY')}</td>
                    <td>{order.c_name}</td>
                    <td>{order.c_phone}</td>
                    <td>{order.c_email}</td>
                    <td>{order.c_service}</td>
                    <td>{order.c_message}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <p>No orders found for the selected date range.</p>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
