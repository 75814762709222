import React, { useState } from "react";
import "./Login.css";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import log from "../../Assets/Login.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const Login = () => {
  const [formData, setFormData] = useState({
    device_id: "AR01QW4561255",
    device_os: "android", // default value
    device_token: "5446",
    password: "",
    email: "",
    app_version: "2", // default value
  });

  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // Initialize navigate instead of history

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // API call to login
      const response = await axios.post(
        "https://lunarsenterprises.com:5004/greenmitra/admin-login",
        {
          email: formData.email,
          password: formData.password,
        }
      );

      // Check if login was successful
      if (response.data.result) {
        // Store the token in localStorage
        localStorage.setItem("user_token", response.data.user_token);
        localStorage.setItem("isLoggedIn", "true");
        // Navigate to the next page (for example, Dashboard)
        navigate("/dashboard"); // Use navigate instead of history.push
      } else {
        setErrorMessage("Invalid email or password.");
      }

      // Clear the form
      setFormData({
        ...formData,
        email: "",
        password: "",
      });
    } catch (error) {
      // Handle error response
      console.error("Error during login:", error);
      setErrorMessage("Something went wrong, please try again.");
    }
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <Row className="w-100">
        {/* First Column */}
        <Col md={6} className="d-none d-md-block">
          <img className="login-page-image" src={log} alt="Login" />
        </Col>

        {/* Second Column */}
        <Col
          md={6}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div style={{ width: "100%" }}>
            <h4 className="text-center mb-4">
              Welcome back to{" "}
              <span className="login-headline"> Green Mitra</span>
            </h4>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  className="form-writing"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  className="form-writing"
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              {errorMessage && (
                <div className="text-danger text-center mb-3">
                  {errorMessage}
                </div>
              )}
              <div className="d-flex justify-content-between mb-3">
                <Button
                  className="login-button w-100"
                  variant="primary"
                  type="submit"
                >
                  Log In
                </Button>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <Button
                  href="/otp"
                  className="login-forget-page"
                  variant="link"
                  style={{ textDecoration: "none", padding: 0 }}
                >
                  Forget Password?
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
